<template>
  <div id="Manage">
    <!-- 产品管理 特权产品-->

    <!-- Dialog 对话框 -->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  ref="form"></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(dialogFonfig.openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
          <el-button type="primary"
                     size="small"
                     @click="clickSync">同步特权渠道</el-button>
          <CommonForm :form="searchForm"
                      :formLabel="searchformLabel"
                      class="filtrateInput">
            <el-button type="primary"
                       @click="searchScenic(searchForm)">搜索</el-button>
            <el-button>导出</el-button>
            <el-button type="text">查看已导出列表</el-button>
            <el-button type="text"
                       @click="resetFilter(searchForm)">重置筛选条件</el-button>
          </CommonForm>
        </div>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTable :tableData="tableData"
                     :tableLabel="tableLabel"
                     :tableConfig="tableConfig"
                     @getPage="getPage">
          <el-table-column label="操作"
                           width="180">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <span @click="clickEditData(scope.$index, scope.row)">编辑</span>
              </el-tooltip>
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="查看规格"
                          placement="top">
                <span @click='clickDeleteData(scope.$index, scope.row)'>查看规格</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </CommonTable>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getPrivilegeGoodsList, getPrivilegeGoodsListDetail, metPrivilegeGoodsList, gerPrivilegeSync
} from 'api/productManage.js'
import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain
  },
  data () {
    return {
      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '新增景点',
        editName: '编辑景点',
        isShowDialogVisible: false
      },

      // form表单 基础数据
      openForm: {
        name: '',
        describe: '',
        sort: '',
        is_hot: '',
        privilege_type: ''
      },
      openFormLabel: [
        {
          model: 'name',
          label: '名称'
        },
        {
          model: 'describe',
          label: '权益简述'
        },
        {
          model: 'sort',
          label: '排序'
        },
        {
          model: 'is_hot',
          label: '是否热门',
          type: 'select',
          options: [
            { value: 0, label: '否' },
            { value: 1, label: '是' }
          ]
        },
        {
          model: 'privilege_type',
          label: '权益分类',
          type: 'select',
          options: [
            { value: 0, text: ' 线上卡卷' }, // 线上卡卷
            { value: 1 }, // 视频会员
            { value: 2 }, // QQ特权
            { value: 3 }, // 知识阅读
            { value: 4 }, // 生活娱乐
            { value: 5 }, // 出行优惠
            { value: 6 }, // 充值优惠
            { value: 7 }, // 酒店民宿
            { value: 8 }
          ]
        }
      ],

      // form表单 搜索数据
      searchForm: {
        name: '',
        is_hot: '',
        type: '',
        privilege_type: ''
      },
      searchformLabel: [
        {
          model: 'name',
          label: '渠道名称',
          width: '300px'
        },
        {
          model: 'is_hot',
          label: '是否热门',
          type: 'select',
          width: '250px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '是' },
            { value: 0, label: '否' }
          ]
        },
        {
          model: 'type',
          label: '产品类别',
          type: 'select',
          width: '250px',
          options: [
            // value:'值',label:'标签'
            { value: 1, label: '线上卡券' },
            { value: 0, label: '优惠充值' }
          ]
        },
        {
          model: 'privilege_type',
          label: '特权分类ID',
          width: '300px'
        }
      ],
      // table 表格 基础数据
      tableData: [],
      tableLabel: [
        {
          prop: 'ID',
          label: 'ID'
        },
        {
          prop: 'logo',
          label: '渠道LOGO',
          type: 'logo'
        },
        {
          prop: 'name',
          label: '渠道名称'
        },
        {
          prop: 'type',
          label: '产品类型'
        },
        {
          prop: 'privilege_type',
          label: '特权分类ID'
        },
        {
          prop: 'sort',
          label: '排序'
        },
        {
          prop: 'state',
          label: '状态',
          type: 'stateNotouch'
        },
        {
          prop: 'is_hot',
          label: '是否热门',
          type: 'yesOrNo'
        },
        {
          prop: 'update_time',
          label: '更新时间',
          width: '200px'
        }
      ],
      tableConfig: {
        isShowLoading: true,
        // 改造状态列显示效果 el-switch

        page: 1,
        total: 30,
        // 加载状态
        loading: false,
        // 显示 选择多行数据
        selection: false
      },

      // 景点-全部-数据
      scenicAlldataObj: {},

      searchData: '', // 存放搜索的数据，分页时使用
      // id最大值
      MaxId: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'privilegeGoods_norms') {
      to.meta.isBack = true
      // 判断是从哪个路由过来的，
      // 如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    }
    // 继续执行
    next()
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      this.updataTableData()
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false
  },
  created () {
    this.isFirstEnter = true
    // 只有第一次进入或者刷新页面后才会执行此钩子函数，使用keep-alive后（2+次）进入不会再执行此钩子函数
  },
  mounted () {
    this.getPrivilegeGoodsList()
  },
  beforeUpdate () {
  },
  watch: {
    scenicAlldataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.scenicAlldataObj.list,
        {
          name: 'name',
          status: 'state',
          id: 'ID',
          update_time: 'update_time'
        }
      )
    }
  },

  methods: {
    // 获得下列分类页的页数
    getPage () {
      // this.getPrivilegeGoodsList()
      if (this.searchData) {
        this.searchData.page = this.tableConfig.page
        this.getPrivilegeGoodsList(this.searchData)
      } else {
        this.getPrivilegeGoodsList()
      }
    },
    // 获取--特权产品列表数据
    getPrivilegeGoodsList (data) {
      if (data) {
        getPrivilegeGoodsList(data).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.scenicAlldataObj = DATA.data
            this.tableConfig.total = DATA.data.total
          }
        })
      } else {
        getPrivilegeGoodsList({
          page: this.tableConfig.page,
          page_rows: 10
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.scenicAlldataObj = DATA.data

            this.tableConfig.total = DATA.data.total
            this.tableConfig.loading = false
          }
        })
      }
    },
    // 详情 特权产品
    getPrivilegeGoodsListDetail (data) {
      return new Promise(resolve => {
        getPrivilegeGoodsListDetail(data).then((res) => {
          resolve(res)
        })
      })
    },

    // 编辑 特权产品
    metPrivilegeGoodsList (data) {
      return new Promise(resolve => {
        metPrivilegeGoodsList(data).then((res) => {
          resolve(res)
        })
      })
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理

      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 id值
    MaxID (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 同步特权渠道
    async clickSync () {
      console.log(123)
      var data = {
        type: this.searchForm.type
      }
      const res = await gerPrivilegeSync(data)
      console.log(res)
      if (res.data.code == 200) {
        this.$message({
          message: res.data.message,
          type: 'success'
        })
      } else {
        this.$message({
          message: res.data.message,
          type: 'error'
        })
      }
    },

    // 确定提交数据操作
    clickSubmitData (type, data) {
      if (type == 'edit') {
        const DATA = {
          id: data.id,
          name: data.name,
          describe: data.describe,
          sort: data.sort,
          status: data.status,
          is_hot: data.is_hot,
          privilege_type: data.privilege_type
        }
        this.metPrivilegeGoodsList(DATA)
        this.getPrivilegeGoodsList()
      }
      this.updataTableData()
      this.dialogFonfig.isShowDialogVisible = false
      this.openForm = this.$options.data().openForm
    },
    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
    },

    // 编辑操作
    async clickEditData (idx, row) {
      const DATA = {
        id: row.ID
      }
      console.log(DATA)
      const result = await this.getPrivilegeGoodsListDetail(DATA)
      console.log(result)
      const data = result.data.data
      this.openForm =
      {
        id: data.id,
        name: data.name,
        describe: data.describe,
        sort: data.sort,
        status: data.status,
        is_hot: data.is_hot,
        privilege_type: data.privilege_type
      }

      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'edit'
    },
    // 查看规格操作
    clickDeleteData (idx, row) {
      console.log(row)
      // let routeData = this.$router.resolve({
      //   name: "privilegeGoods_norms",
      //   query: { goods_id: row.ID },
      // });
      // window.open(routeData.href);
      this.$router.push({ name: 'privilegeGoods_norms', query: { goods_id: row.ID } })
    },
    // 刷新表格
    updataTableData () {
      this.getPrivilegeGoodsList()
    },
    // 搜索景点元素
    searchScenic (searchForm) {
      console.log(searchForm)
      const data = {
        name: searchForm.name,
        is_hot: searchForm.is_hot,
        type: searchForm.type,
        privilege_type: searchForm.privilege_type
      }
      this.searchData = data
      this.getPrivilegeGoodsList(data)
    },
    // 重置筛选条件
    resetFilter (searchForm) {
      searchForm.name = '',
        searchForm.is_hot = '',
        searchForm.type = '',
        searchForm.privilege_type = ''
      this.searchData = ''
      this.getPrivilegeGoodsList()
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  width: 100%;
  height: 720px;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  /deep/ .el-dialog {
    width: 30%;
  }
  .filtrateInput {
    background: #f8f7f7;
    width: 55%;
    padding: 12px 8px;
    padding-right: 45%;
    margin: 8px 0px;
  }
  span {
    font-size: 14 px;
    margin-left: 8px;
    color: #0486fe;
    cursor: pointer;
  }
  /deep/ .el-main {
    width: 100%;
  }
}
</style>
